<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>个性推荐策略</el-breadcrumb-item>
        <el-breadcrumb-item>查看选股策略</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card shadow="always" class="add-container">
      <div>
        <div class="title">选股策略</div>
        <el-form ref="form" :model="strategyForm" :rules="rules" label-position="right" label-width="100px" class="policy-content-form">
          <el-form-item label="策略名称">
            <!-- <el-input v-model="policyForm.name" style="width: 270px" maxlength="200"></el-input> -->
            <div style="word-break: break-all;width: 270px">{{strategyForm.strategyName}}</div>
          </el-form-item>
          <el-form-item label="策略简介">
            <div style="word-break: break-all;">{{strategyForm.strategyDescription}}</div>
            <!-- <el-input type="textarea" v-model="policyForm.introduction" style="width: 270px" maxlength="100"></el-input> -->
          </el-form-item>
          <el-form-item label="更新方式">
            <div>{{strategyForm.updateMethod==="1"?'自动重复':'手动单次'}}</div>
          </el-form-item>
          <el-form-item label="推送角色">
            <div>{{roleList}}</div>
          </el-form-item>
        </el-form>
      </div>
  <div v-if="strategyForm.updateMethod==='1'">
        <div class="title">自动重复设置</div>
        <el-form ref="forms" :model="strategyForm" label-position="right" label-width="100px" class="policy-analysis-form">
          <el-form-item label="数据开始日期">
            <div>{{strategyForm.automaticStartDate}}</div>
          </el-form-item>
          <el-form-item label="选择账号">
            <div>{{strategyForm.account}}</div>
          </el-form-item>
          <el-form-item label="选择个性策略">
            <div>{{strategyForm.categoryName}}</div>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="strategyForm.updateMethod==='2'">
        <div class="title">手动单次设置</div>
        <el-form ref="forms" :model="analysisForm" label-position="right" label-width="100px" class="policy-analysis-form">
          <el-form-item label="数据日期">
            <div>{{strategyForm.automaticStartDate}} ~ {{strategyForm.manualDate}}</div>
          </el-form-item>
          <el-form-item label="股票数量">
            <div>{{strategyForm.stockList.length}}</div>
          </el-form-item>
          <el-form-item label="股票列表">
            <div>
              <el-table :height="strategyForm.stockList.length>4?'249':49+40*strategyForm.stockList.length" :data="strategyForm.stockList" border style="width: 100%">
                <el-table-column type="index" label="序号" width="180">
                </el-table-column>
                <el-table-column prop="stockCode" label="股票代码" width="180">
                </el-table-column>
                <el-table-column prop="stockName" label="股票名称" width="180">
                </el-table-column>
              </el-table>
            </div>

          </el-form-item>

        </el-form>
      </div>
      <div class="btn-group">
        <div class="btn submit" @click="submit">修改</div>
        <div class="btn cancel" @click="back">返回</div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, ref, toRefs, onMounted, getCurrentInstance } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { formatterDate } from "../../../utils/format";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const form = ref();
    const state = reactive({
      strategyForm:{},
      roleList:''
    });

    if (route.path.indexOf("edit") > -1) {
      state.type = "edit";
    } else if (route.path.indexOf("add") > -1) {
      state.type = "add";
    }

    onMounted(() => {
      getStrategyDetail()
    });
    const getStrategyDetail = () => {
      let params = `?strategyId=${route.query.id}`
      proxy.$get("/cts/back/strategy/detail" + params).then((res) => {
        if (res.resultState === "200") {
          state.strategyForm = res.data
          const {roleList} = state.strategyForm
          let list =[]
          roleList.forEach(item => {
            list.push(item.roleName)
          });
          state.roleList = list.splice(',').join(' / ')
          // ElMessage({
          //   message: `${res.msg}`,
          //   type: "success",
          // });
        }
      })
        .catch((err) => { });
    };
    
    const submit = () => {
      router.push({ path: '/content/contentManagement/addOrEdit', query: { type: 'edit', id: route.query.id } })
    }
    const back = () => {
      // route.query.type
      router.push({ path: '/content/selectedAreas/personalityRecommendationStrategy' })
    }
    return {
      ...toRefs(state),
      submit,
      back,
      form,
    };
  },
};
</script>

<style lang="scss" scoped>
.add-container {
  .title {
    font-weight: bold;
  }
  .policy-content-form {
    padding: 20px;
    width: 450px;
  }
  .policy-analysis-form {
    padding: 20px;
    width: 490px;
    .choose-container {
      width: 370px;
      max-height: 260px;
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0 0 10px 0;
      .input {
        display: flex;
        .el-button {
          margin-left: 5px;
        }
      }
    }
  }
  v::deep .el-form-item__label {
    margin-right: 60px;
  }
  .btn-group {
    margin-left: 35px;
    display: flex;
    .btn {
      width: 80px;
      border: 1px solid #dcdcdc;
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
      &.submit {
        background: #bd3124;
        color: #fff;
        margin-right: 15px;
      }
    }
  }
}
</style>
